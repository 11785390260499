import React, {useEffect} from 'react';
import {Route, useLocation, useParams} from "react-router-dom";
import {ROUTERS_MAP} from "../../utils/routes";
import ManageVideo from "./ManageVideo/ManageVideo";
import {ChannelsPage} from "./ChannelsPage";
import ChatBlock from "../../shared/chat/ChatBlock";
import {useDispatch, useSelector} from "react-redux";
import {isCheckAuth, scrollTop} from "../../utils/helperFunctions";
import {GetAuthCheck, LogOut} from "../../redux/actions";
import {history} from "../../utils/history";

const ManageDataPage = () => {
  const isAuth = isCheckAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    scrollTop();
    dispatch(GetAuthCheck());
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout') {
        // Проверяем ключ 'logout' и выполняем разлогинивание
        dispatch(LogOut())
        history.push(ROUTERS_MAP.LOGIN);
      }
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paramsUuid = urlParams.get('uuid')

    if (!paramsUuid && !isAuth) {
      history.push(ROUTERS_MAP.LOGIN);
    }
  }, [isAuth, location?.pathname]);

    return (
        <div>
            <Route exact path={ROUTERS_MAP.MANAGE_DATA} component={ManageVideo}/>
            <Route path={ROUTERS_MAP.CHANNELS} component={ChannelsPage}/>
            {isAuth ? <ChatBlock/> : null}
        </div>
    );
};

export default ManageDataPage;