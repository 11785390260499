// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './synchronize.scss';
import {ErrorIcon, RefreshGreenIcon, SuccessIcon} from "../../../../image";


//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {GetCalendarSynchronize} from "../../../../redux/actions";
import {Toast} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Synchronize(props) {
    const {loading, getEvents} = props;
    const [requestLoading, setRequestLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [toastTitle, setToastTitle] = useState('');
    const {t} = useTranslation();
    const toggleToast = () => setShowToast(!showToast);

    useEffect(() => {
        setRequestLoading(loading)
    }, [loading])

    function toggleSynchronize() {
        setRequestLoading(true)
        props.GetCalendarSynchronize().then((res) => {
            if (res?.authorization_url) {
                window.open(res?.authorization_url)
                const urlParams = new URLSearchParams(res?.authorization_url);
                const callback = urlParams.get('callback')
                callback().then(res => {
                    console.log(res, 'callback result');
                })
            }
            switch (res?.synchronize_result) {
                case 'calendars_list_sync':
                    setShowToast(true)
                    setToastTitle(t('toast_success_title'))
                    setErrorText(t('success_synchronized_calendar'))
                    break;
                case 'event_list_sync':
                    setShowToast(true)
                    setToastTitle(t('toast_success_title'))
                    setErrorText(t('success_synchronized_calendar_v2'))
                    break;
                case 'no_token':
                    if (res?.synchronize_neutronix_result !== 'event_list_sync'){
                        setShowToast(true)
                        setToastTitle('Error')
                        setErrorText(t('was_no_synchronization'))
                    }

                    break;
            }
            getEvents()
        }).finally(() => {
            setRequestLoading(false)
        })
    }

    function ErrorToast() {
        return <Toast show={showToast} autohide={true} onClose={toggleToast} className={'upload_video_answer'}>
            <div className="toasblock">
                {toastTitle === 'Error' ? <ErrorIcon/> : <SuccessIcon/>}
                <div className="toasblock__wrap">
                    <span className="toasblock__wrap--title">{toastTitle}</span>
                    <span className="toasblock__wrap--text">{errorText}</span>
                </div>
            </div>
        </Toast>
    }

    return <>
        <RefreshGreenIcon onClick={toggleSynchronize}
                          className={`${requestLoading ? 'spinner' : ''}`}/>
        {ErrorToast()}
    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    GetCalendarSynchronize
};

export default connect(mapStateToProps, mapDispatchToProps)(Synchronize);
