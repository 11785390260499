import React, {useEffect, useRef, useState} from 'react';
import {SearchIcon} from "../../../../image";
import {GetVideosSearch} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import './PersonDropDown.scss';
import {CheckBox} from "../../../CheckBox";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {getAuthorName} from "../../../../utils/helperFunctions";
import {deleteChar, ProtocolEditorContext, TABS_ACTIONS_DROPDOWN} from "../../helpers";

const PersonDropDown = ({activeTab}) => {
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const [findUsers, setFindUsers] = useState([]);
    const inputRef = useRef(null);
    const [isAssignee, setIsAssignee] = useState(activeTab === TABS_ACTIONS_DROPDOWN.ASSIGNEE_PERSON)
    const controllerRef = useRef(null);
    const {editor} = React.useContext(ProtocolEditorContext);

    const createSignal = () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        controllerRef.current = controller;
        return signal;
    }

    const searchUser = async (value) => {
        try {
            setSearchText(value)
            const response = await dispatch(GetVideosSearch({
                search_query: value,
                exclude_videos: true,
                exclude_transcriptions: true,
                exclude_persons: false
            }, true, createSignal()));
            setFindUsers(response?.persons || []);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [])

    const onHandleClickUser = (e, user) => {
        deleteChar(editor, "/");
        editor
            .chain()
            .focus()
            .insertNonEditableText({
                text: ` @${user?.first_name} ${user.last_name}${isAssignee ? '(assignee)' : ''}`,
                personName: `${user?.first_name} ${user.last_name}`,
                personId: user?.id,
                personType:  isAssignee ? 'assignee' : 'mention',
            })
            .insertContent(' ')
            .run();
        e.stopPropagation();
    }

    return (
        <div className="persons-dropdown">
            <div className="persons-dropdown--input-wrap">
                <SearchIcon/>
                <input
                    value={searchText}
                    onChange={(e) => searchUser(e?.target?.value || '')}
                    type="text" placeholder={t('input_search')}
                    ref={inputRef}
                />
            </div>
            <div
                tabIndex={0}
                className="persons-dropdown__find-users custom-scroll">
                {findUsers.map((item) => (
                    <div className="person-item"
                         onClick={(e) => onHandleClickUser(e, item)}
                         key={item?.id}>

                        <img
                            src={generateMediaUrl(item?.avatar)}
                            key={item.id}
                            alt=""
                        />
                        <div className="person_info">
                            <div className="name">
                                {getAuthorName(item, null, i18n.language)}
                            </div>
                            <div className="email">
                                {item.emails?.[0] || 'user@gmail.com'}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <CheckBox
                className="person-item--radio"
                checked={isAssignee}
                onChange={(e) => setIsAssignee(e.target.checked)}
                label={t('make_assignee')}
            />
        </div>
    );
};

export default PersonDropDown;