export const PROTOCOL_CONSTS = {
    GET_PROTOCOL: "GET_PROTOCOL",
    ADD_PROTOCOL: "ADD_PROTOCOL",
    UPDATE_PROTOCOL: "UPDATE_PROTOCOL",
    UPDATE_PROTOCOL_POSITION: "UPDATE_PROTOCOL_POSITION",
    DELETE_PROTOCOL: "DELETE_PROTOCOL",
    ADD_PROTOCOL_HIGHLIGHTS: "ADD_PROTOCOL_HIGHLIGHTS",
    DELETE_PROTOCOL_HIGHLIGHTS: "DELETE_PROTOCOL_HIGHLIGHTS",
    TOGGLE_EDIT_FLAG: "TOGGLE_EDIT_FLAG"
};
