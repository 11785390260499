import React, {forwardRef, useImperativeHandle, useState} from 'react';
import './EditorDropdownActions.scss';
import {AssigneeIcon, ChecklistIcon, PersonStrokeIcon, TimestampIcon} from "../../../../image";
import {Text} from '../../../../modules'
import {useTranslation} from "react-i18next";
import {deleteChar, formatTime, ProtocolEditorContext, TABS_ACTIONS_DROPDOWN} from "../../helpers";
import {useSelector} from "react-redux";
import PersonDropDown from "../PersonDropDown/PersonDropDown";


const EditorDropdownActions = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const {editor, videoTime} = React.useContext(ProtocolEditorContext);
    const userProfile = useSelector(state => state.profile.userProfile);
    const personId = userProfile?.attached_person?.id;
    const [activeTab, setActiveTab] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0)

    const onClickTimeStamp = (e) => {
        e.stopPropagation();
        deleteChar(editor, '/');
        editor.commands.insertContent(
            {
                type: 'text',
                text: ` ${formatTime(videoTime)}`,
                marks: [
                    {
                        type: 'custom_tag',
                        attrs: {
                            tag: 'span',
                            class: 'video-time',
                            videoTime: formatTime(videoTime)
                        },
                    }]
            }
        )
        // editor.commands.insertContent(`{"video_time": "${formatTime(videoTime)}"} `);
    }

    const upHandler = () => {
        setSelectedIndex((selectedIndex - 1 < 0 ? 0 : selectedIndex - 1))
    }

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1 > 3? 3 : selectedIndex + 1))
    }

    const enterHandler = (event) => {
        if(selectedIndex === 0){
            onClickTimeStamp(event)
        }else if(selectedIndex === 1){
            onHandleClickDefaultPerson(event)
        }else if(selectedIndex === 2){
            onHandleClickAssigneePerson(event)
        }else if(selectedIndex === 3){
            onHandleClickTaskList(event)
        }
    }

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
                upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                downHandler()
                return true
            }

            if (event.key === 'Enter') {
                enterHandler(event)
                return true
            }

            return false
        },
    }))

    const onHandleClickDefaultPerson = (e) => {
        setActiveTab(TABS_ACTIONS_DROPDOWN.DEFAULT_PERSON);
        e.stopPropagation();
    }
    const onHandleClickAssigneePerson = (e) => {
        setActiveTab(TABS_ACTIONS_DROPDOWN.ASSIGNEE_PERSON);
        e.stopPropagation();
    }

    const onHandleClickTaskList = (e) => {
        deleteChar(editor, "/"); // Удаляем символ "@"
        const { state } = editor;
        const { $from } = state.selection; // Текущая позиция курсора
        const textBefore = $from.parent.textBetween(0, $from.parentOffset, "\n", "\n").trim();
        // Проверяем есть ли текст перед курсором
        if (textBefore.length > 0) {
            editor.chain().focus().insertContent("<p></p>").run();
        }
        editor.chain().focus().toggleTaskList().run();

        //     editor
    //         .chain()
    //         .focus()
    //         .insertContent({
    //             type: 'taskList',
    //             content: [
    //                 {
    //                     type: 'taskItem',
    //                     attrs: { checked: false, class: 'custom-checkbox' }, // Параметр checked управляет состоянием чекбокса
    //                     content: [
    //                         {
    //                             type: 'paragraph',
    //                             content: [
    //                                 {
    //                                     type: 'text',
    //                                     text: `-`, // Текст, связанный с чекбоксом
    //                                 },
    //                             ],
    //                         },
    //                     ],
    //                 },
    //             ],
    //         })
    // .run();
    //     deleteChar(editor, `-`);
    //     editor.focus();
        // editor.commands.insertContent(`[] `);
        e.stopPropagation();
    }

    return (
        <div className="editor-dropdown" onClick={(e)=>e.stopPropagation()}>
            {Object.keys(TABS_ACTIONS_DROPDOWN).includes(activeTab) ?
                <PersonDropDown activeTab={activeTab}/>
                :
                <>
                    <div
                        className={`editor-dropdown__item ${selectedIndex === 0 ? 'editor-dropdown__item--active' : ''}`}
                        onClick={onClickTimeStamp}
                    >
                        <TimestampIcon/>
                        <Text size="f14">{t('timestamp')}</Text>
                    </div>
                    <div
                        className={`editor-dropdown__item ${selectedIndex === 1 ? 'editor-dropdown__item--active' : ''}`}
                        onClick={onHandleClickDefaultPerson}
                    >
                        <PersonStrokeIcon/>
                        <Text size="f14">{t('menu_item_persons')}</Text>
                    </div>
                    <div
                        className={`editor-dropdown__item ${selectedIndex === 2 ? 'editor-dropdown__item--active' : ''}`}
                        onClick={onHandleClickAssigneePerson}
                    >
                        <AssigneeIcon/>
                        <Text size="f14">{t('assignee')}</Text>
                    </div>
                    <div
                        className={`editor-dropdown__item ${selectedIndex === 3 ? 'editor-dropdown__item--active' : ''}`}
                         onClick={onHandleClickTaskList}
                    >
                        <ChecklistIcon/>
                        <Text size="f14">{t('checklist')}</Text>
                    </div>
                </>
            }
        </div>
    );
});

export default EditorDropdownActions;