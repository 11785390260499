import React, {forwardRef} from 'react';
import './HelperEditorDropdown.scss';
import {Text} from "../../../../modules";
import {deleteChar, formatTime, ProtocolEditorContext} from "../../helpers";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const HelperEditorDropdown = forwardRef((props, ref) => {
    const {editor, videoTime} = React.useContext(ProtocolEditorContext);
    const userProfile = useSelector(state => state.profile.userProfile);
    const personId = userProfile?.attached_person?.id;
    const {t} = useTranslation();


    const onHandleClickTime = (e) => {
        deleteChar(editor, '@');
        editor.commands.insertContent(
            {
                type: 'text',
                text: ` ${formatTime(videoTime)}`,
                marks: [
                    {
                        type: 'custom_tag',
                        attrs: {
                            tag: 'span',
                            class: 'video-time',
                            videoTime: formatTime(videoTime)
                        },
                    }]
            }
        )
        e.stopPropagation();
    }

    const onHandleClickPerson = (e) => {
        deleteChar(editor, "@");
        editor
            .chain()
            .focus()
            .insertNonEditableText({
                // text: `@${userProfile?.first_name} ${userProfile.last_name}:{"person_id": ${personId}, "type": "mention"}`,
                text: ` @${userProfile?.first_name} ${userProfile.last_name}`,
                personName: `${userProfile?.first_name} ${userProfile.last_name}`,
                personId: personId,
                personType: "mention",
            })
            .insertContent(' ')
            .run();
        e.stopPropagation();
    }

    const onHandleClickAssignee = (e) => {
        deleteChar(editor, "@");
        editor
            .chain()
            .focus()
            .insertNonEditableText({
                // text: `@${userProfile?.first_name} ${userProfile.last_name}:{"person_id": ${personId}, "type": "assignee"}`,
                text: ` @${userProfile?.first_name} ${userProfile.last_name}("assignee")`,
                personName: `${userProfile?.first_name} ${userProfile.last_name}`,
                personId: personId,
                personType: "assignee",
            })
            .insertContent(' ')
            .run();
        e.stopPropagation();
    }

    return (
        <div className="helper-block" onClick={(e)=>e.stopPropagation()}>
            <Text onClick={onHandleClickTime} size="f10" className="helper-block--item">
                {formatTime(videoTime)}
            </Text>
            <Text onClick={onHandleClickPerson} size="f10" className="helper-block--item">
                {t('person').toLowerCase()}: {userProfile?.first_name} {userProfile.last_name}
            </Text>
            <Text onClick={onHandleClickAssignee} size="f10" className="helper-block--item">
                {t('assignee').toLowerCase()}: {userProfile?.first_name} {userProfile.last_name}
            </Text>
        </div>
    );
});

export default HelperEditorDropdown;