// Import packages
import React, {useEffect} from 'react';
import {Route, useLocation, useRouteMatch} from 'react-router-dom';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import './dashboard.scss';

// Import Components
import Menu from '../../shared/features/Menu';
import Users from './containers/Users';
import Videos from "./containers/Videos/Videos";
import Profile from "./containers/Profile/Profile";
import Searcher from "../../shared/features/Searcher/Searcher";
import MLIndication from "./containers/MLIndication/MLIndication";
import Roles from "./containers/Roles/Roles";

// Import Utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetAuthCheck, SetIsMobile, GetNotifications, GetNotificationsCount, LogOut} from "../../redux/actions";
import {history} from "../../utils/history";
import UserVideosPage from "./containers/UserVideosPage/UserVideosPage";
import {io} from "socket.io-client";
import {HOST_NAME} from "../../redux/api";
import {ChainPage} from "./containers/Chains";
import {ROUTERS_MAP} from "../../utils/routes";
import ChannelPage from "../KnowledgeData/ChannelPage/ChannelPage";
import ChatBlock from "../../shared/chat/ChatBlock";
import {isCheckAuth, scrollTop} from "../../utils/helperFunctions";

function Dashboard(props) {
    const location = useLocation();
    const {path} = useRouteMatch();
    const dispatch = useDispatch();

    const isAuth = isCheckAuth();
    const checkWindowWidth = () => {
        if (window.innerWidth > 768) {
            props.SetIsMobile(false);
        } else if (window.innerWidth <= 768) {
            props.SetIsMobile(true);
        }
    };

    useEffect(()=>{
        // socketRef.current = io(`${HOST_NAME}/user_notifications`)
        // socketRef?.current?.on('updated', (message) => {
        //     console.log('message', message);
        // });
        // socket.emit('start', props.token);

        // socket.on('updated', message => {
        //     console.log('socket message', message);
        // });
        scrollTop();
    }, [])

    useEffect(() => {
        const socket = io(`${HOST_NAME}/user_notification`);
        const socketFavorites = io(`${HOST_NAME}/document_updates`)
        // const socket = io(`${HOST_NAME}/user_notification`, {
        //     transportOptions: {
        //         polling: {
        //             extraHeaders: {
        //                 Authorization: `Bearer ${props.token}`
        //             }
        //         }
        //     }
        // });
        socket.on('connect', () => {
            console.log('Connected, sending token...', typeof props.token);
            socket.emit('login',  props.token);
        });

        socket.on('start', () => {
            console.log('Authenticated!');
        });

        socket.on('error', (error) => {
            console.error('Error:', error);
        });

        // Listening to events
        socket.on('updated', () => {
            props.GetNotifications()
            props.GetNotificationsCount()
        });

        // Clean up on component unmount
        return () => {
            socket.emit('logout');
            socket.disconnect();
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const paramsUuid = urlParams.get('uuid')

        if (!paramsUuid && !props.token) {
            history.push(ROUTERS_MAP.LOGIN);
        }
    }, [props.token, location?.pathname]);

    useEffect(() => {
        checkWindowWidth();
        window.onresize = () => {
            checkWindowWidth();
        };
        /* eslint-disable-next-line */
        props.GetAuthCheck()
        window.addEventListener('storage', (event) => {
            if (event.key === 'logout') {
                // Проверяем ключ 'logout' и выполняем разлогинивание
                dispatch(LogOut())
                history.push(ROUTERS_MAP.LOGIN);
            }
        });
    }, []);

    return (
        <div className="dashboard">
            <Menu/>
            <Searcher name={''}/>
            <Route path={`${path}/videos`} component={Videos}/>
            <Route path={`${ROUTERS_MAP.CHANNEL}/:channelId/chains/:chainId`} exact component={ChainPage}/>
            <Route path={`${ROUTERS_MAP.CHANNEL}/:channelId`} exact component={ChannelPage}/>
            <Route path={`${path}/videos/userVideos/:id`} component={UserVideosPage}/>
            <Route path={`${path}/users`} component={Users}/>
            <Route path={`${path}/indication`} component={MLIndication}/>
            <Route path={`${path}/profile`} component={Profile}/>
            <Route path={`${path}/roles`} component={Roles}/>
            {isAuth ? <ChatBlock/> : null}
        </div>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['token', 'userRoles', 'token'])
};

const mapDispatchToProps = {
    SetIsMobile,
    GetAuthCheck,
    GetNotificationsCount,
    GetNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
