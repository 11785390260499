import React from 'react';

const FontWeight = {
	"book": 400,
	liteMedium: 450,
	medium: 500,
	bold: 600
}
const FontSize = {
	f10: {
		fontSize: '10px',
		lineHeight: '16px'
	},
	f12: {
		fontSize: '12px',
		lineHeight: '16px'
	},
	f14: {
		fontSize: '14px',
		lineHeight: '20px'
	},
	f15: {
		fontSize: '15px',
		lineHeight: '25px'
	},
	f16: {
		fontSize: '16px',
		lineHeight: '20px'
	},
	f18: {
		fontSize: '18px',
		lineHeight: '24px'
	},
	f20: {
		fontSize: '20px',
		lineHeight: '28px'
	}
}

const Text = (props) => {
	const {
		as: Component = 'span',
		font="liteMedium",
		color="#4A4A4A",
		size="f12",
		children,
		...rest
	} = props;

	return (
		<Component
			{...rest}
			style={{
				fontWeight: FontWeight[props.font],
				fontSize: FontSize[props.size].fontSize,
				lineHeight: FontSize[props.size].lineHeight,
				color,
			}}
		>
			{children}
		</Component>
	);
};

export default Text;