import React, {useCallback, useState} from 'react';
import './ChannelsList.scss';
import {Popover, Tooltip} from "antd";
import {Accordion, useAccordionButton} from "react-bootstrap";
import {ArrowLeftIcon, ChainIcon, DeletingIcon, EditIcon, EllipsisIcon, PlusNewIcon} from "../../../../../image";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DeleteChannel, GetChannels} from "../../../../../redux/actions";
import {ROUTERS_MAP} from "../../../../../utils/routes";
import {history} from "../../../../../utils/history";
import {hasPermission} from "../../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../../utils/constants";
import {getChainsFromChannel} from "../../../../../modules/Chain/components/SettingsAutoChainsModal/helpers";
import EditChainInput from "../../../../../modules/Chain/components/SettingsAutoChainsModal/components/EditChainInput";
import {CheckBox} from "../../../../../modules/CheckBox";
import useEditChain from "../../../../../modules/Chain/components/SettingsAutoChainsModal/useEditChain";
import {Button} from "../../../../../modules/Button";


const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];

const ChannelsList = ({selectedChannel, selectedChannelId}) => {
	const {t} = useTranslation();
	const channels = useSelector(state => state.channels?.channels || []);
	const privateChannels = channels.filter((item) => item?.is_private);
	const publicChannels = channels.filter((item) => !item?.is_private);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const videos = useSelector(state => state.videos?.videosList?.results || [])
	const [chainName, setChainName] = useState('');
	const [openChains, setOpenChains] = useState({});
	const [isVisibleAddForm, setIsVisibleAddForm] = useState(false);
	const toggleIsVisibleAddForm = useCallback(() => setIsVisibleAddForm(prevState => !prevState), []);
	const [editChainInfo, setEditChainInfo] = useState('');
	const findChannel = channels.find((item) => item?.id === selectedChannelId);
	const chainsAll = useSelector(state => state.chains?.chainsList || []);
	const closeAndClearInput = () => {
		toggleIsVisibleAddForm();
		setChainName('');
		setEditChainInfo({});
	}

	const {AddChain, DeleteChain, UpdateChain} = useEditChain({closeAndClearInput, chainName, findChannel, editChainInfo});

	function CustomToggle({children, eventKey}) {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<ArrowLeftIcon
				className="channel-item__header--arrow"
				onClick={decoratedOnClick}
			/>
		);
	}

	const deleteChannel = async (id) => {
		try {
			setOpen(false);
			await dispatch(DeleteChannel(id));
			const channels = await dispatch(GetChannels());
			selectedChannel(channels.filter((item) => !item?.is_private)?.[0]?.id)
		} catch (e) {
			console.error(e)
		}
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
	}

	function navigateTo(data) {
		history.push({
			pathname: ROUTERS_MAP.CHANNEL + `/${data?.id}`,
			state: {
				routs: [
					{
						path: ROUTERS_MAP.MANAGE_DATA,
						name: t('menu_item_manage_data')
					},
					{
						path: ROUTERS_MAP.CHANNELS,
						name: t('menu_item_channels')
					}
				]
			}

		})
	}

	const onHandleEditChangeChain = (id) => {
		const findChain = chainsAll.find((item) => item?.id === id);
		setEditChainInfo(findChain || {})
		toggleIsVisibleAddForm();
		setChainName(findChain.name);
	}

	const renderAccordionChannel = ({data, index}) => {
		const countVideos = videos.reduce((count, item) => {
			if(item?.channels.includes(data?.id)) {
				return count + 1
			}
			return count
		}, 0)

		return (
			<Accordion
				onClick={() => selectedChannel(data?.id)}
				className={`channel-item ${selectedChannelId === data?.id ? 'active' : ''}`}
				style={{
					'--chain_bg': data?.is_private ? 'rgba(26, 187, 120, 0.10)'
						: tagColors[index >= 5 ? index % 5 : index] + '14',
					'--chain_color': data?.is_private ? "#1ABB78" : tagColors[index >= 5 ? index % 5 : index],
				}}
			>
				<Accordion.Item eventKey={index}>
					<div
						className={`channel-item__header`}
					>
						<div
							onClick={(e) => {
								navigateTo(data);
								e.stopPropagation()
							}}
							className={`channel-item__header--badge`}

						>
							{data?.name}
						</div>
						<CustomToggle eventKey={index} className="channel-item__header--arrow"/>
						<span className="channel-item__header--count">{countVideos} {t('video')}</span>
						{(hasPermission(ROLE_ITEMS.CHANNELS_EDIT) || hasPermission(ROLE_ITEMS.CHANNELS_DELETE)) ?
							<Popover
								arrow={false}
								onOpenChange={handleOpenChange}
								trigger='click'
								placement="bottomLeft"
								overlayClassName='popover_edit-menu'
								content={
									<>
										{hasPermission(ROLE_ITEMS.CHANNELS_DELETE) ?
											<div
												className={'popover_edit-menu--item delete'}
												onClick={(e) => {
													e.stopPropagation();
													deleteChannel(data?.id)
												}}
											>
												<DeletingIcon className={''}/> {t('button_delete')}
											</div>
											: null}
									</>
								}
							>
								<div className="popover_edit-menu--settings" onClick={(e) => e.stopPropagation()}>
									<EllipsisIcon/>
								</div>
							</Popover>
							: null}
					</div>
					<Accordion.Body>
						<div className="chain-block--title">
							{t('chains')}
						</div>
						{(!isVisibleAddForm && (hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || data?.is_private)) ?
							<Button
								text={t('add_chain')}
								variant="ghost"
								onClick={toggleIsVisibleAddForm}
								leftIcon={<PlusNewIcon/>}
								className="chain-block--add-btn"
								size="xs"
							/>
							: null}
						{isVisibleAddForm && !editChainInfo?.id ?
							<EditChainInput
								onSave={() => AddChain(data)}
								setChainName={setChainName}
								chainName={chainName}
								closeAndClearInput={closeAndClearInput}
							/>
							: null}
						{(data?.chains || []).map((chain, index) => (
								<>
									{isVisibleAddForm && editChainInfo?.id === chain?.id ?
										<EditChainInput
											onSave={() => UpdateChain(findChannel)}
											setChainName={setChainName}
											chainName={chainName}
											closeAndClearInput={closeAndClearInput}
										/>
										:
										<div className="chain-block__item" key={index}>
											{(chain?.name || '').length > 25 ?
												<Tooltip title={chain?.name} placement="top">
													<div className="chain-block__item--badge">
														<ChainIcon/>
														<span>{chain?.name}</span>
													</div>
												</Tooltip>
												:
												<div className="chain-block__item--badge">
													<ChainIcon/>
													<span>{chain?.name}</span>
												</div>
											}

											<Popover
												arrow={false}
												onOpenChange={(e)=>handleOpenChange(e, chain?.id)}
												open={openChains?.[chain?.id]}
												trigger='click'
												placement="bottomLeft"
												overlayClassName='popover_edit-menu'
												content={
													<>
														<div
															className={'popover_edit-menu--item'}
															onClick={(e) => {
																setOpenChains({
																	...openChains,
																	[chain?.id]: false
																});
																onHandleEditChangeChain(chain?.id)
															}}
														>
															<EditIcon className={''}/> {t('button_edit')}
														</div>
														<div
															className={'popover_edit-menu--item delete'}
															onClick={(e) => {
																setOpenChains({
																	...openChains,
																	[chain?.id]: false
																});
																DeleteChain(chain?.id)
															}}
														>
															<DeletingIcon className={''}/> {t('button_delete')}
														</div>
													</>
												}
											>
												<div className="popover_edit-menu--settings" onClick={(e) => e.stopPropagation()}>
													<EllipsisIcon/>
												</div>
											</Popover>
										</div>
									}
								</>
							))}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>

		)
	}

	return (
		<div className="channels-list">
			<span className="channels-list--title">{t('channels_and_chains')}</span>
			<div className="channels-list__block">
				<span className="channels-list__block--title">{t('private')}</span>
				<div className="channels-list__block__list">
					{privateChannels?.map((data, index) => (
						renderAccordionChannel({data, index})
					))}
				</div>
			</div>
			<div className="channels-list__block">
				<span className="channels-list__block--title">{t('public')}</span>
				<div className="channels-list__block__list">
					{publicChannels.map((data, index) => (
						renderAccordionChannel({data, index})
					))}
				</div>
			</div>
		</div>
	);
};

export default ChannelsList;