import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {EditorContent, useEditor} from "@tiptap/react";
import {
    convertToTiptapJSON,
    CustomParagraph,
    CustomTag,
    parseTipTapToMarkdown,
    ProtocolEditorContext,
    UserColors
} from "../../helpers";
import {Popover} from "antd";
import HelperEditorDropdown from "../HelperEditorDropdown/HelperEditorDropdown";
import {
    CheckActionItemIcon,
    DotsIcon,
    FileFillIcon,
    FileStrokeIcon,
    PlusNewIcon,
    StarIcon,
    UncheckActionItemIcon
} from "../../../../image";
import {
    AddProtocolHighlight,
    DeleteProtocolHighlight,
    GetProtocol,
    ToggleEditProtocolFlagService, UpdateProtocol
} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import './ProtocolEditor.scss';
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import SettingsProtocolDropDown from "../SettingsProtocolDropDown/SettingsProtocolDropDown";
import {StarterKit} from "@tiptap/starter-kit";
import helperMention from "./components/HelperMention";
import actionsMention from "./components/ActionsMention";
import {Mention} from "@tiptap/extension-mention";
import {NonEditableText} from "./components/NodeEditableText";
import {Placeholder} from "@tiptap/extension-placeholder";
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import {updateCheckboxesText} from "../../../../utils/markdown";
import {isCheckAuth} from "../../../../utils/helperFunctions";

const ProtocolEditor =  forwardRef((props, ref) => {
    const {isEdit, onCloseEdit, protocol, text, providerRef, onChangeTypeShowForm, onSaveText, videoTime,updateClick} = props;
    const containerRef = useRef();
    const isAuth = isCheckAuth();
    const dispatch = useDispatch();
    const params = useParams();
    const userInfo = useSelector(state => state.profile.userProfile);
    const getRandomElement = list => list[Math.floor(Math.random() * list.length)]
    const userAvatar = useSelector(state => state.profile.userAvatar);
    const getRandomColor = () => getRandomElement(UserColors)
    const [isEditable, setIsEditable] = useState(isEdit); // Состояние для отслеживания редактируемости
    const [openSettings, setOpenSettings] = useState(false);
    const isEditProtocolId = useSelector(state => state.protocol?.isEditProtocolId)

    function handleOpenSettings(newOpen) {
        setOpenSettings(newOpen);
    }

    // Создаём редактор только один раз
    let editor = useEditor({
        onCreate: ({editor}) => {
            if (providerRef.current) {
                providerRef.current.on('synced', () => {
                    const content = convertToTiptapJSON(protocol?.text || '', false);
                    console.log('conte', content)
                    editor.commands.setContent(content);
                });
            }
        },
        extensions: [
            actionsMention,
            Mention.configure({
                suggestion: helperMention,
            }),
            NonEditableText,
            Placeholder.configure({
                placeholder: 'Write something, or press \'/\' for commands...',

            }),
            StarterKit.configure({
                paragraph: false
            }),
            CustomParagraph,
            CustomTag,
            ...(providerRef?.current
                ? [
                    Collaboration.configure({
                        document: providerRef.current.doc,
                    }),
                ]
                : []),
            ...(providerRef?.current
                ? [
                    CollaborationCursor.configure({
                        provider: providerRef.current,
                        user: {
                            name: `${userInfo?.first_name} ${userInfo?.last_name}`,
                            color: getRandomColor(),
                            avatar: generateMediaUrl(userAvatar),
                        },
                        render: (user) => {
                            const cursor = document.createElement('span');
                            cursor.classList.add('collaboration-cursor__caret');
                            cursor.classList.add(`cursor__caret_${protocol?.id}`);
                            cursor.setAttribute('style', `border-color: ${user.color};`);

                            const label = document.createElement('div');
                            const labelSpan = document.createElement('span');
                            labelSpan.insertBefore(
                                document.createTextNode(user.name),
                                null
                            );
                            label.insertBefore(labelSpan, null);
                            label.classList.add('collaboration-cursor__label');
                            label.setAttribute(
                                'style',
                                `background-color: ${user.color};`
                            );
                            cursor.insertBefore(label, null);

                            const img = document.createElement('img');
                            img.setAttribute('src', user?.avatar);
                            label.insertBefore(img, null);

                            return cursor;
                        },
                    }),
                    // TaskList,
                    TaskItem.configure({
                        nested: true,
                        HTMLAttributes: {
                            class: 'custom-checkbox333',
                        },
                    }),
                    TaskList.configure({
                        HTMLAttributes: {
                            class: 'custom-checkbox',
                            id: `markdownChk_${Math.random(0, 9999)}`
                        },
                    })
                ]
                : []),
        ],
        editable: false,
    });


    useEffect(() => {

        return () => {
            if(isEditProtocolId === protocol?.id){
                dispatch(ToggleEditProtocolFlagService(null))
            }
            providerRef.current.disconnect();
            editor.destroy();
        };
    }, [])
    const eventHandlerRef = useRef(null);

    // useEffect(() => {
    //     const handleChange = (event) => {
    //         const label = event.target.parentElement;
    //         const item = label.parentElement;
    //         console.log('item',item)
    //         const isItem = item.hasAttribute("data-checked");
    //         if(isItem ){
    //             const isChecked = item.getAttribute("data-checked") !== 'true';
    //             const test = item.getAttribute("data-checked");
    //             // const foundText = item?.querySelector('div')?.outerText;
    //             const pElement = item?.querySelector('div');
    //             const clonedP = pElement.cloneNode(true); // Создаем копию элемента
    //
    //             clonedP.querySelectorAll('span').forEach((span) => span.remove()); // Удаляем все <span>
    //             const foundText = clonedP.textContent.trim();
    //             const text = parseTipTapToMarkdown(editor.getJSON()?.content || []);
    //             const getText = updateCheckboxesText(text, foundText,  isChecked);
    //             const content = convertToTiptapJSON(getText, false);
    //             // setIsEditable((prev) => !prev); // Меняем состояние
    //             editor.setEditable(true);
    //             editor.chain().focus();
    //             editor.commands.clearContent();
    //             editor.commands.insertContent(content);
    //             // setIsEditable((prev) => !prev); // Меняем состояние
    //             editor.setEditable(false);
    //
    //             // if(!isEditable){
    //             //     dispatch(GetProtocol(params?.videoId));
    //             // }
    //         }
    //
    //     };
    //
    //     const container = containerRef.current;
    //     console.log('ddd', container)
    //     if (container) {
    //         const arrElements = container.querySelectorAll('.custom-checkbox333') || [];
    //         console.log('arrElements',arrElements)
    //         // Удаление старых обработчиков
    //         // if (eventHandlerRef.current) {
    //         //     arrElements.forEach((item) => {
    //         //         item.removeEventListener('change', eventHandlerRef.current);
    //         //     });
    //         // }
    //
    //         // Сохранение ссылки на новый обработчик
    //         eventHandlerRef.current = handleChange;
    //
    //         // Добавление нового обработчика
    //         arrElements.forEach((item) => {
    //             item.addEventListener('change', eventHandlerRef.current); // передаем item через bind
    //         });
    //     }
    //
    //     // Очистка обработчиков при размонтировании компонента
    //     return () => {
    //         console.log('BBBBB')
    //         if (container) {
    //             const arrElements = container.querySelectorAll('.custom-checkbox333') || [];
    //             arrElements.forEach((item) => {
    //                 item.removeEventListener('change', eventHandlerRef.current);
    //             });
    //         }
    //     };
    // }, [protocol, editor.getText()]);

    useEffect(() => {
        editor.setEditable(isEditable);

        if(isEditable){
            editor.commands.focus();
            if(!protocol?.id) editor.commands.insertContent(' @');
        }
    }, [isEditable])


    const openMentionDropdown = () => {
        if (editor && isEditable) {
            editor.commands.focus(); // Фокус на редактор
            editor.commands.insertContent(' @');
        }
    };

    const isCursorAtEnd = () => {
        const {state} = editor;
        // Получаем текущую позицию курсора
        const cursorPosition = state.selection.$head.pos;
        // Получаем длину документа (последняя позиция)
        const documentLength = state.doc.content.size;
        // Если курсор на позиции последнего символа, значит он в конце
        return cursorPosition === (documentLength - 1);
    };

    const updateMarkProtocol = async (type) => {
        if (isEdit) {
            onCloseEdit();
        }
        if (type === 'add') {
            if (isAuth) {
                await dispatch(AddProtocolHighlight({protocol_text_id: protocol?.id}))
            }
        } else {
            await dispatch(DeleteProtocolHighlight({protocol_text_id: protocol?.id}));
        }
        await dispatch(GetProtocol(params?.videoId));
    }
    const updateActionProtocol = async (type) => {
        if (isEdit) {
            onCloseEdit();
        }
        if (type === 'add') {
            if (isAuth) {
                await dispatch(UpdateProtocol(protocol?.id, {is_action_item: true}))
            }
        } else {
            await dispatch(UpdateProtocol(protocol?.id, {is_action_item: false}));
        }
        await dispatch(GetProtocol(params?.videoId));
    }

    useEffect(() => {
        const handleClickOutside = async (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target) && isEditable) {
                console.log("Клик был вне блока");
                console.log('event',event.target)
                console.log('containerRef.current',containerRef.current)
                onCloseEdit();
                console.log('editor.getJSON()', editor.getJSON())
                console.log('RTTTTT', parseTipTapToMarkdown(editor.getJSON()?.content || []))
                onSaveText(parseTipTapToMarkdown(editor.getJSON()?.content || []), !!protocol?.id);
                setIsEditable((prev) => !prev); // Меняем состояние
                // await dispatch(GetProtocol(params?.videoId));
            }
        };
        if (isEditable) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isEditable]);

    function adjustCursorPosition() {
        const cursors = document.querySelectorAll(`.cursor__caret_${protocol?.id}`);
        cursors.forEach((cursor) => {
            const editor = cursor.closest('.tiptap-editor');
            if (!editor) return;

            const cursorRect = cursor.getBoundingClientRect();
            const editorRect = editor.getBoundingClientRect();

            const distanceToRight = editorRect.right - cursorRect.right;
            if (distanceToRight < 160) {
                cursor.classList.add('label-reverse');
            } else {
                cursor.classList.remove('label-reverse');
            }
        });
    }

    adjustCursorPosition()
    const test = (event) => {
            event.stopPropagation();
            const label = event.target.parentElement;
            const item = label.parentElement;
            const isItem = item.hasAttribute("data-checked");
            if(isItem && !editor.isEditable){
                const isChecked = item.getAttribute("data-checked") !== 'true';
                const test = item.getAttribute("data-checked");
                // const foundText = item?.querySelector('div')?.outerText;
                const pElement = item?.querySelector('div');
                const clonedP = pElement.cloneNode(true); // Создаем копию элемента
                clonedP.querySelectorAll('span').forEach((span) => span.remove()); // Удаляем все <span>
                const foundText = clonedP.textContent.trim();
                const text = parseTipTapToMarkdown(editor.getJSON()?.content || []);
                const getText = updateCheckboxesText(text, foundText,  isChecked);
                const content = convertToTiptapJSON(getText, false);
                setIsEditable((prev) => !prev); // Меняем состояние
                editor.setEditable(true);
                editor.chain().focus();
                console.log('fff')
                item.click();
                // editor.commands.clearContent();
                // editor.commands.insertContent(content);
                // setIsEditable((prev) => !prev); // Меняем состояние
                // editor.setEditable(false);

                // if(!isEditable){
                //     dispatch(GetProtocol(params?.videoId));
                // }
            }

    }

    return (
        <ProtocolEditorContext.Provider value={{editor, videoTime}}>
            <div
                style={{
                    height: containerRef?.current?.offsetHeight || 'auto',
                    cursor: "grab"
                }}
                className={`${isEditProtocolId ? 'hidden' : ''} protocol-block__actions`}
            >
                <Popover
                    arrow={false}
                    trigger='click'
                    placement="bottom"
                    overlayClassName="setting-protocol-popover"
                    destroyTooltipOnHide={true}
                    onOpenChange={handleOpenSettings}
                    open={protocol ? openSettings : false}
                    content={
                        <SettingsProtocolDropDown
                            onEdit={(e) => {
                                e.stopPropagation();
                                dispatch(ToggleEditProtocolFlagService(protocol?.id))
                                setIsEditable((prev) => !prev); // Меняем состояние
                                setOpenSettings(false)
                            }}
                            protocol={protocol}
                            onClosePopup={setOpenSettings}
                            onChangeTypeShowForm={onChangeTypeShowForm}
                        />
                    }
                >
                    <DotsIcon
                        className={`protocol-block--plus ${isEdit || openSettings ? '' : 'setting-protocol-popover'}`}/>
                </Popover>
            </div>
            <div
                onMouseEnter={(e)=>{
                    updateClick(false)
                }}
                onMouseLeave={(e)=>updateClick(true)}
                onClick={(e)=> {
                    // updateClick(false);
                    e.stopPropagation()
                }}
                ref={containerRef}
                className={`protocol-editor ${!isEditable ? 'protocol-editor--no-edit' : ''} `}
            >
                <EditorContent
                    onClick={(e) => {
                        test(e);
                        e.stopPropagation();
                        if (isCursorAtEnd()) {
                            openMentionDropdown();
                        }
                    }}
                    onDoubleClick={()=>{
                        if(!isEditable){
                            dispatch(ToggleEditProtocolFlagService(protocol?.id))
                            setIsEditable(true)
                        }
                    }}
                    className={`tiptap-editor ${(isEditable || openSettings) ? 'tiptap-editor--edit' : ''}`} editor={editor}/>

                <div
                    className={`${isEditProtocolId ? 'hidden' : ''} protocol-block__actions`}
                >
                    {isEditable ?
                        <Popover
                            arrow={false}
                            trigger='click'
                            placement="bottom"
                            overlayClassName='chains-menu'
                            destroyTooltipOnHide={true}
                            content={
                                <HelperEditorDropdown/>
                            }
                        >
                            <PlusNewIcon className="protocol-block--plus"/>
                        </Popover>
                        : null}
                    {(isAuth && text) ?
                        <>
                            {protocol?.is_action_item ?
                                <CheckActionItemIcon
                                    onClick={() => updateActionProtocol('delete')}
                                    className={`protocol-block--file`}/>
                            :
                            <UncheckActionItemIcon
                                onClick={() => updateActionProtocol('add')}
                                className={`protocol-block--file ${isEdit ? '' : 'protocol-block--hover'}`}/>
                            }
                        </>
                        : null
                    }
                    {(isAuth && text) ?
                        <>
                            {protocol?.marked ?
                                <StarIcon
                                    className={`protocol-block--start fill`}
                                    onClick={() => updateMarkProtocol('delete')}
                                />
                                :
                                <StarIcon
                                    className={`protocol-block--start ${isEdit ? '' : 'protocol-block--hover'}`}
                                    onClick={() => updateMarkProtocol('add')}
                                />
                            }
                        </>
                        : null}
                </div>
            </div>
        </ProtocolEditorContext.Provider>

    );
});

export default ProtocolEditor;