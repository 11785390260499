import React, {useEffect, useState} from 'react';
import {CheckTokenService} from "../../redux/actions";
import {useDispatch} from "react-redux";
import {history} from "../../utils/history";
import {ROUTERS_MAP} from "../../utils/routes";
import {Loader} from "../../shared/features/Loader";
import './MainPage.scss';

const MainPage = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const isCheckToken = async () => {
		try{
			const response = await dispatch(CheckTokenService());
			setLoading(false);
			if(response){
				history.push(ROUTERS_MAP.KNOWLEDGE_DATA);
			}else{
				history.push(ROUTERS_MAP.LOGIN)
			}
		}catch (e) {
			setLoading(false);
			history.push(ROUTERS_MAP.LOGIN)
			console.error(e)
		}
	}

	useEffect(()=>{
		isCheckToken()
	},[]);

	return (
		<div className="main-page-container">
			{loading ? <Loader size={150}/> : null}
		</div>
	);
};

export default MainPage;