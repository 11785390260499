//text (string) - Основной текст, где будет проходить поиск
//findText (string) - Лейбл чекбокса, будет искаться в тексте
//checked (boolean) - Значение checkbox
export const updateCheckboxesText = (text, findText, checked, openMenu) => {
	// Разделение текста на строки
	const processedText = text || '';
	const lines = processedText.split('\n');

	// Определение шаблона для чекбоксов, включая варианты с дефисом и разными состояниями
	// const checkboxPattern = /^-?\s?\[([ xX])\]\s?(.+)?$/;
	const checkboxPattern = /^\s*-?\s?\[([ xX]?)\]\s*(.*)?$/;

	// Переменная для хранения нового текста с обновленными чекбоксами
	let updatedText = '';

	// Перебор строк текста
	lines.forEach(line => {
		const match = line.match(checkboxPattern);
		if (match) {
			const checkboxState = match[1];  // Состояние чекбокса (x, X или пробел)
			const labelText = match[2];  // Текст метки

			// Проверка, совпадает ли текст метки с искомым
			if (labelText.trim() === findText) {
				// Обновление состояния чекбокса
				const newState = checked ? 'x' : ' ';
				updatedText += line.replace(checkboxPattern, `[${newState}] ${labelText}\n`);
			} else {
				// Оставление без изменений
				updatedText += line + '\n';
			}
		} else {
			// Добавление строки без изменений, если это не чекбокс
			updatedText += line + '\n';
		}
	});
	return updatedText.trim();
};


export const updateDetailsTagMarkdown = (text, openMenu, isDefault) => {
	// Разделение текста на строки
	const lines = (text || '').split('\n');

	let updatedText = '';

	// Перебор строк текста
	lines.forEach(line => {
		let updatedLine = line;

		if (line.trim().startsWith('<details')) {
			if (openMenu) {
				// Если openMenu = true и у тега <details> нет атрибута open, добавляем его
				if (!line.includes('open')) {
					updatedLine = line.replace('<details', '<details open');
				}
				updatedLine = updatedLine.replace(/<summary>\s*Expand\s*<\/summary>/i, '<summary>Collapse</summary>');
			} else {
				// Если openMenu = false, удаляем атрибут open, если он есть
				updatedLine = line.replace(/\sopen\s*/i, '');
				updatedLine = updatedLine.replace(/<summary>\s*Collapse\s*<\/summary>/i, '<summary>Expand</summary>');
			}
		}

		if (isDefault) {
			updatedLine = updatedLine.replace(/<summary>\s*Collapse\s*<\/summary>/i, '<summary>Expand</summary>');
		}

		updatedText += updatedLine + '\n';
	});

	return updatedText.trim();
}