import React, {useContext} from 'react';
import './SettingsProtocolDropDown.scss';
import {ArrowLongIcon, DeleteIcon, PencilIcon, ShareStrokeIcon} from "../../../../image";
import {Text} from "../../../../modules";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ToastContext} from "../../../Toast/ToastContext";
import {
    DeleteProtocol,
    GetLink,
} from "../../../../redux/actions";
import {SHOW_ADD_FORM_TYPES} from "../../helpers";

const SettingsProtocolDropDown = (props) => {
    const {t} = useTranslation();
    const {onEdit,protocol,onClosePopup,onChangeTypeShowForm} = props;
    const dispatch = useDispatch();
    const {setToastInfo} = useContext(ToastContext);

    const onHandleClickShare = async () => {
        try {
            const response = await dispatch(GetLink({
                protocol_text_id: protocol?.id
            }));
            navigator.clipboard.writeText(response.text);
            setToastInfo({
                isShow: true,
                type: 'success',
                text: t("link_copied_to_clipboard")
            });
            onClosePopup();
        }catch (e) {
            console.error(e)
        }
    }

    const changePositions = async (type, e) => {
        try{
            e.stopPropagation();
            onChangeTypeShowForm(type);
            onClosePopup();
        }catch (e) {
            console.error(e)
        }
    }

    const deleteProtocol = async () => {
        try{
            await dispatch(DeleteProtocol(protocol));
        }catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="settings-dropdown">
            <div className="settings-dropdown__item" onClick={onEdit}>
                <PencilIcon />
                <Text size="f14" font="liteMedium">{t('button_edit')}</Text>
            </div>
            <div className="settings-dropdown__item" onClick={onHandleClickShare}>
                <ShareStrokeIcon />
                <Text size="f14" font="liteMedium">{t('button_share')}</Text>
            </div>
            <div className="settings-dropdown__item" onClick={(e)=>changePositions(SHOW_ADD_FORM_TYPES.UP,e)}>
                <ArrowLongIcon className="settings-dropdown__item--arrow-top"/>
                <Text size="f14" font="liteMedium">{t('create_above')}</Text>
            </div>
            <div className="settings-dropdown__item" onClick={(e)=>changePositions(SHOW_ADD_FORM_TYPES.DOWN, e)}>
                <ArrowLongIcon className="settings-dropdown__item--arrow-down"/>
                <Text size="f14" font="liteMedium">{t('create_below')}</Text>
            </div>
            <div className="settings-dropdown__item settings-dropdown__item--delete" onClick={deleteProtocol}>
                <DeleteIcon />
                <Text size="f14" font="liteMedium">{t('button_delete')}</Text>
            </div>
        </div>
    );
};

export default SettingsProtocolDropDown;