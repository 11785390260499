import React, {useEffect, useRef, useState} from 'react';
import './FilterParticipants.scss';
import {useDispatch, useSelector} from "react-redux";
import {Popover, Tooltip} from "antd";
import {ArrowLeftIcon, CloseStrokeIcon, FilterIcon, SearchIcon} from "../../../../image";
import {useTranslation} from "react-i18next";
import {GetUsersPersons, GetVideosSearch} from "../../../../redux/actions";
import {getAuthorName} from "../../../../utils/helperFunctions";
import {ALL_FILTERS} from "../../../../modules/Filters/helpers";
import {CheckBox} from "../../../../modules/CheckBox";
import {Button} from "../../../../modules/Button";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {
	GetNotesService,
	updateApiStatusNotesListService,
	updateFiltersNotesListService
} from "../../../../redux/actions/notesActions";
import {ApiStatus} from "../../../../utils/constants";
import {getLabelTypes} from "../../helpers";
import {useLocation} from "react-router-dom";


const TYPES = {
	MENTIONED: "mentioned",
	ASSIGNED: "assignee"
}

const FilterParticipants = ({pageType}) => {
	const {t, i18n} = useTranslation();
	const filters = useSelector(state => state.notes.notesList.filters);
	const selectedPersonTypeState = useSelector(state => state.notes.notesList.filters?.person_types || []);
	const selectedPesronIdState = useSelector(state => state.notes.notesList.filters?.person_id);
	const selectedUsersState = useSelector(state => state.filtersOrSort?.[pageType]?.filters?.[ALL_FILTERS.PARTICIPANTS] || []);
	const [selectedItems, setSelectedItems] = useState([...selectedUsersState]);
	const [open, setOpen] = useState(false);
	const [openFindPopup, setOpenFindPopup] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [searchText, setSearchText] = useState('');
	const dispatch = useDispatch();
	const [findUsers, setFindUsers] = useState([]);
	const [selectedPerson, setSelectedPerson] = useState();
	const [allUsers, setAllUsers] = useState([]);
	const [typeUser, setTypeUser] = useState([TYPES.MENTIONED, TYPES.ASSIGNED]);
	const [isCheckUser, setIsCheckUser] = useState(true);
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const personIdQuery = urlParams.get('personId')
	const inputRef = useRef(null);

	const getAllUsers = async (value) => {
		try {
			const response = await dispatch(GetUsersPersons({filter: 'all', limit: 1000}, true));
			const users = response?.results || [];
			setAllUsers(users);
			return users;
		} catch (error) {
			console.log(error)
		}
	}

	const labels = getLabelTypes(i18n.language);

	const findUserState = async (id) => {
		if(id) {
			const users = allUsers.length ? allUsers : await getAllUsers();
			const findUser = users.find((item) => item.person_id === id);

			if(findUser) {
				setSelectedPerson({
					id: findUser.person_id,
					avatar: findUser.avatar,
					first_name: findUser.first_name,
					last_name: findUser.last_name,
				})
			}
			setTypeUser([TYPES.MENTIONED, TYPES.ASSIGNED]);
			setIsCheckUser(true);
		}
	}

	const searchUser = async (value) => {
		try {
			setSearchText(value)
			const response = await dispatch(GetVideosSearch({
				search_query: value,
				exclude_videos: true,
				exclude_transcriptions: true,
				exclude_persons: false
			}, true));
			setOpenFindPopup(response?.persons?.length)
			setFindUsers(response?.persons || []);
		} catch (error) {
			console.log(error)
		}
	}

	const onSelectedPerson = (data) => {
		setSelectedPerson(data);
		setTypeUser([TYPES.MENTIONED, TYPES.ASSIGNED]);
		setOpenFindPopup(false)
	}

	const getLabel = () => {
		let text = '';
		if(selectedPesronIdState) {
			const findUser = allUsers.find((item) => item.person_id === selectedPesronIdState);
			text = `${findUser?.first_name} ${findUser?.last_name} ${selectedPersonTypeState.length === 1 ? `(${(selectedPersonTypeState).includes(TYPES.MENTIONED) ? labels.MENTIONED : labels.ASSIGNED})` : ''}`
		} else {
			text = 'All'
		}

		return text
	}

	function handleOpenFindPopupChange(newOpen) {
		setOpenFindPopup(newOpen);
	}

	function handleOpenChange(newOpen) {
		setOpen(newOpen);
		setOpenTooltip(false);
		setSearchText('');
		if(!newOpen) {
			setSelectedItems(selectedUsersState);
			const id = selectedPesronIdState;
			if(id) {
				findUserState(Number(id));
			} else {
				setSelectedPerson(null)
			}
		}
	}

	function handleOpenTooltipChange(newOpen) {
		setOpenTooltip(newOpen);
	}

	const getNotes = async (data) => {
		try {
			await dispatch(updateApiStatusNotesListService(ApiStatus.LOADING));
			await dispatch(GetNotesService({
				limit: 20,
				offset: 0,
				...data
			}));
			await dispatch(updateApiStatusNotesListService(ApiStatus.SUCCESS));
		} catch (e) {
			await dispatch(updateApiStatusNotesListService(ApiStatus.ERROR));
		}
	}

	const handleCancelClick = () => {
		setOpen(false);
		setOpenTooltip(false);
		const id = selectedPesronIdState;
		if(id) {
			findUserState(Number(id));
		} else {
			setSelectedPerson(null)
		}
	}


	const handleApplyClick = async () => {
		try {
			setOpen(false);
			setOpenTooltip(false);
			let obj = filters;
			if(isCheckUser) {
				if(typeUser.length) {
					obj = {
						...obj,
						person_types: typeUser,
						person_id: selectedPerson?.id
					}
				}
			} else {
				delete obj.person_types;
				delete obj.person_id;
			}
			dispatch(updateFiltersNotesListService({
				...obj,
			}))
			await getNotes(obj)
		} catch (error) {
			console.log(error)
		}
	}

	const deleteFilter = async () => {
		const obj = filters;
		delete obj.person_types;
		delete obj.person_id;

		setSelectedPerson(null);
		setTypeUser([]);
		await getNotes(obj);
	}

	const onCheckedUser = (checked) => {
		setIsCheckUser(checked);
		if(checked) {
			setTypeUser([TYPES.MENTIONED, TYPES.ASSIGNED])
		} else {
			setTypeUser([])
		}
	}

	const onChangeTypeUser = (e, type) => {
		const isChecked = e?.target?.checked;
		if(isChecked) {
			const value = [...typeUser];
			value.push(type)
			setTypeUser(value);
		} else {
			setTypeUser(typeUser.filter((item) => item !== type))
		}
	}

	useEffect(() => {
		if(inputRef.current) {
			inputRef.current.focus();
		}
	}, [open])

	useEffect(() => {
		const id = selectedPesronIdState;

		if(id) {
			findUserState(id);
		}
		getAllUsers()

	}, []);

	useEffect(() => {
		if(personIdQuery) {
			findUserState(Number(personIdQuery));
		}
	}, [personIdQuery])

	const RenderTooltip = () => {
		return (
			<>
				{(selectedPesronIdState) ?

					<Tooltip
						open={open ? false : openTooltip}
						onOpenChange={handleOpenTooltipChange}
						placement="top"
						title={<RenderTooltipText/>}
					>
        <span
	        className="filter-note-person__label--text"
        >
            {`${t('involved')}: ${getLabel()}`}
        </span>
					</Tooltip>
					:
					<span
						className="filter-note-person__label--text"
					>
            {`${t('involved')}: ${getLabel()}`}
        </span>
				}
			</>
		)
	}

	const RenderTooltipText = () => {
		const findUser = allUsers.find((item) => item.person_id === selectedPesronIdState);
		const text = selectedPesronIdState ? `${findUser?.first_name} ${findUser?.last_name}` : ''

		return (
			<div className="filter-note-person__tooltip">
				<div className="filter-note-person__tooltip--text">{text}</div>
			</div>
		)
	}

	const handleKeyDown = (event) => {
		if(event.key === "Enter" && selectedItems.length) {
			handleApplyClick()
		}
	};

	return (
		<Popover
			arrow={false}
			trigger='click'
			placement="bottomLeft"
			overlayClassName='filter-note-person'
			onOpenChange={handleOpenChange}
			destroyTooltipOnHide={true}
			open={open}
			content={
				<div className="filter-note-person__container" onKeyDown={handleKeyDown}>
					<div style={{position: 'relative'}}>

						<Popover
							arrow={false}
							trigger='click'
							placement="bottom"
							overlayClassName='filter-note-person__find-users'
							onOpenChange={handleOpenFindPopupChange}
							open={openFindPopup}
							content={
								<>
									{findUsers?.length ?
										<div
											tabIndex={0}
											className="filter-note-person__find-users--container custom-scroll">
											{findUsers.map((item) => (
												<div className="person-item"
												     onClick={() => onSelectedPerson(item)}
												     key={item?.id}>
													<CheckBox
														className="person-item--radio"
														checked={item?.id === selectedPerson?.id}
														type={'radio'}
													/>
													<img
														src={generateMediaUrl(item?.avatar)}
														key={item.id}
														alt=""
													/>
													<div className="person_info">
														<div className="name">
															{getAuthorName(item, null, i18n.language)}
														</div>
														<div className="email">
															{item.emails?.[0] || 'user@gmail.com'}
														</div>
													</div>
												</div>
											))}
										</div>
										:
										<>
											{searchText ?
												<div className="filter-note-person__empty">
													<span>{t('no_found_search_item')}</span>
												</div>
												: null}
										</>
									}
								</>
							}
						>
							<div className="filter-note-person__search" tabIndex={0}>
								<div className="filter-participant__search--input-wrap">
									<SearchIcon/>
									<input
										value={searchText}
										onChange={(e) => searchUser(e?.target?.value || '')}
										type="text" placeholder={t('input_search')}
										ref={inputRef}
									/>
								</div>
							</div>
						</Popover>
					</div>
					{selectedPerson ?
						<>
							<div className="filter-note-person__user-info">
								<div className="person-item"
								     onClick={() => onCheckedUser(!isCheckUser)}
								     key={selectedPerson.id}>
									<CheckBox
										className="person-item--radio"
										checked={isCheckUser}
									/>
									<img
										src={generateMediaUrl(selectedPerson?.avatar)}
										key={selectedPerson.id}
										alt=""
									/>
									<div className="person_info">
										<div className="name">
											{getAuthorName(selectedPerson, null, i18n.language)}
										</div>
										<div className="email">
											{selectedPerson.email || 'user@gmail.com'}
										</div>
									</div>
								</div>
								<div className="filter-note-person__user-info--actions">
									<CheckBox
										className="person-item--radio"
										checked={typeUser.includes(TYPES.MENTIONED)}
										onChange={(e) => onChangeTypeUser(e, TYPES.MENTIONED)}
										label={TYPES.MENTIONED}
										disabled={!isCheckUser}
									/>
									<CheckBox
										className="person-item--radio"
										checked={typeUser.includes(TYPES.ASSIGNED)}
										disabled={!isCheckUser}
										onChange={(e) => onChangeTypeUser(e, TYPES.ASSIGNED)}
										label={TYPES.ASSIGNED}
									/>
								</div>
							</div>
						</>
						:
						<>

						</>
					}
					<div className="filter-note-person__footer">
						<Button
							text={t('button_cancel')}
							variant="secondary"
							size="xs"
							onClick={handleCancelClick}
						/>
						<Button
							text={t('apply')}
							variant="primary"
							size="xs"
							disabled={!selectedPerson}
							onClick={handleApplyClick}
						/>
					</div>
				</div>
			}
		>
			<div className={`filter-note-person__label ${selectedPesronIdState ? 'selected' : ''}`}>
				<div className={`filter-note-person__wrap`}>
					<FilterIcon/>
					<RenderTooltip/>
					{!selectedPesronIdState ?
						<ArrowLeftIcon className={`filter-note-person__label--arrow ${open ? 'open' : ''}`}/> : null}
				</div>
				{selectedPesronIdState ?
					<CloseStrokeIcon
						onClick={deleteFilter}
						className={`filter-note-person__label--arrow`}
					/> : null}
			</div>
		</Popover>
	);
};

export default FilterParticipants;