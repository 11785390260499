import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {io} from 'socket.io-client';

import './recorder.scss';
import {RecordIcon, ShareStrokeIcon} from "../../../image";

import Timer from "./Timer";

import {HOST_NAME} from "../../../redux/api";
import {history} from "../../../utils/history";
import {useTranslation} from "react-i18next";
import {Button} from "../../../modules/Button";


let mediaRecorder = null;
let dataChunks = [];

function NewRecorder(props) {
    const username = useRef(null);
    const socketRef = useRef(null);

    const location = useLocation();
    const linkRef = useRef();
    const recordingRef = useRef(false);

    const {t} = useTranslation();

    const [screenStream, setScreenStream] = useState();
    const [voiceStream, setVoiceStream] = useState();
    const [recording, setRecording] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const [hasVoice, setHasVoice] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);


    useEffect(() => {
        if (screenStream && voiceStream) {
            startRecording();
        }
    }, [screenStream, voiceStream])

    useEffect(() => {
        return () => {
            stopRecording()
        }
    }, [])

    function setPermissionAccess() {
        // Check if getUserMedia is supported by the browser
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            // Request microphone access
            navigator.mediaDevices.getUserMedia({audio: true})
                .then(function () {
                    toggleRecord()
                })
                .catch(function () {
                    // Microphone access is denied or an error occurred
                    console.log('Microphone access is denied or an error occurred');
                });
        } else {
            // getUserMedia is not supported by the browser
            console.log('getUserMedia is not supported by this browser');
        }
    }

    async function toggleRecord() {
        username.current = `User_${Date.now().toString().slice(-4)}`;
        //TODO
        socketRef.current = io(`${HOST_NAME}/video`)
        socketRef?.current?.on('message', (message) => {
            if (message?.cancelled_by_server) {
                setIsCanceled(true)
                stopRecording()
            }
            history.push({
                pathname: `/dashboard/videos/video/${message?.video_id}`,
                state: {
                    openEdit: true,
                    routs: [
                        {
                            name: t('button_record')
                        },
                    ]
                }
            })
            // Handle the received message here
        });

        if (navigator?.mediaDevices?.getDisplayMedia) {
            try {
                navigator.mediaDevices.getDisplayMedia({audio: true, video: true})
                    .then(function (stream) {
                        setScreenStream(stream);
                        if (navigator?.mediaDevices?.getUserMedia) {
                            if (stream) {
                                try {
                                    navigator?.mediaDevices?.getUserMedia({
                                        audio: true
                                    }).then((voise_stream) => {
                                        setHasVoice(!!stream.getAudioTracks()?.length)
                                        setVoiceStream(voise_stream);
                                    })
                                } catch (e) {
                                    setVoiceStream('unavailable');
                                }
                            }
                        } else {
                            console.warn('*** getUserMedia not supported');
                        }
                    })
            } catch (e) {
                console.error('*** getDisplayMedia', e);
            }
        } else {
            console.warn('*** getDisplayMedia not supported');
        }
    }

    function startRecording() {
        if (screenStream && voiceStream && !mediaRecorder) {
            setRecording(true);
            setDownloaded(true);
            recordingRef.current = true;
            linkRef.current.removeAttribute('href');
            linkRef.current.removeAttribute('download');

            let mediaStream;
            if (voiceStream === 'unavailable') {
                mediaStream = screenStream;
            } else {
                mediaStream = new MediaStream();
                const audioContext = new AudioContext();

                if (hasVoice) {
                    let sourceNode1 = audioContext.createMediaStreamSource(voiceStream);
                    let sourceNode2 = audioContext.createMediaStreamSource(screenStream);

                    let destinationNode = audioContext.createMediaStreamDestination();

                    sourceNode1.connect(destinationNode);
                    sourceNode2.connect(destinationNode);

                    let mergedStream = destinationNode.stream;

                    mediaStream.addTrack(...mergedStream.getTracks())
                } else {
                    mediaStream.addTrack(...voiceStream.getAudioTracks())
                }

                mediaStream.addTrack(screenStream?.getVideoTracks()[0])
            }
            socketRef.current.emit('start', props.token);
            mediaRecorder = new MediaRecorder(mediaStream);
            mediaRecorder.addEventListener('dataavailable', handleDataAvailable);
            mediaRecorder.start(250);
        }
    }

    function handleDataAvailable(event) {
        if (recordingRef.current) {
            dataChunks.push(event.data);
            socketRef.current.emit('frame', event.data)
        }
    }


    function stopRecording() {
        setRecording(false);

        const videoBlob = new Blob(dataChunks, {
            type: 'video/mp4',
        });

        const videoSrc = URL.createObjectURL(videoBlob);

        if (linkRef?.current) {
            linkRef.current.href = videoSrc;
            linkRef.current.download = `${Date.now()}-${username.current}.mp4`;
        }
        mediaRecorder && (mediaRecorder.stop())

        if (screenStream) {
            // Stop screen sharing
            const screenTracks = screenStream.getTracks();
            screenTracks.forEach(track => track.stop());
            setScreenStream(undefined)
        }

        if (voiceStream) {
            // Stop audio recording
            const audioTracks = voiceStream.getTracks();
            audioTracks.forEach(track => track.stop());
            setVoiceStream(undefined)
        }

        recordingRef.current && (recordingRef.current = false);
        socketRef.current && !isCanceled && (socketRef.current.emit('end', username.current));

        mediaRecorder = null;
        dataChunks = [];
    }

    const onStop = () => {
        if (mediaRecorder) {
            stopRecording()
        }
    };

    return (
        <>
            <Button
              onClick={!recording ? setPermissionAccess : onStop}
              text={!recording ? t('button_record') : <Timer/>}
              variant="primary"
              size="s"
              leftIcon={!recording ? <RecordIcon/> : null}
              className={`search__buttons-record ${!recording ? '' : 'timer'}`}
              style={{
                  display: location.pathname.includes('/dashboard/videos/video') ? 'none' : 'flex'
              }}
              />

            <a ref={linkRef} className={'download_btn'} style={{
                display: downloaded ? "block" : "none"
            }}>{t('button_download')}</a>
        </>
    );
}

export default NewRecorder;
