import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {useAuth} from "react-oidc-context";

import './logIn.scss';

import {getPropsFromState} from "../../../redux/mapStateToProps";
import {CheckTokenService, GetChannels, LoginUser} from "../../../redux/actions";
import {uris} from "../../../utils/constants";
import {history} from "../../../utils/history";
import {useProductName} from "../../../shared/features/productName"
import config from "../../../utils/config";
import {useTranslation} from "react-i18next";
import {ROUTERS_MAP} from "../../../utils/routes";
import {Loader} from "../../../shared/features/Loader";


function LogIn(props) {
    // Записываем в константу хук из react-router-dom
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loginDirty, setLoginDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [loginError, setLoginError] = useState('Логин пустой');
    const [passwordError, setPasswordError] = useState('Пароль пустой');
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const isCheckToken = async () => {
        try {
            const response = await dispatch(CheckTokenService());
            setLoading(false);
            if (response) {
                history.push(ROUTERS_MAP.KNOWLEDGE_DATA);
            }
        } catch (e) {
            setLoading(false);
            console.error(e)
        }
    }

    useEffect(() => {
        isCheckToken()
    }, [])

    // Функция проверяющая правильность логина и пароля
    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'login':
                setLoginDirty(true);
                break;
            case 'password':
                setPasswordDirty(true);
                break;
            default:
        }
    };

    // Функция проверяющая правильность логина
    const loginHandler = (e) => {
        const {value} = e.target;
        setLogin(value);
        if (!value || value.length < 3) {
            setLoginError(t('validate_login_text'));
        } else {
            setLoginError('');
        }
    };

    // Функция проверяющая правильность пароля
    const passwordHandler = (e) => {
        const {value} = e.target;
        setPassword(value);
        if (!value || value.length < 3) {
            setPasswordError(t('validate_password_text'));
        } else {
            setPasswordError('');
        }
    };

    // Функция, которая запускает функцию отправки запроса, переносит пользователя на начальную страницу после авторизации
    const handleSubmit = () => {
        props.LoginUser({
            email: login,
            password
        }).then(() => {
            localStorage.removeItem('logout');
            history.push(uris.LOGIN_REDIRECT);
            dispatch(GetChannels())
        })
            .catch(() => {
                setPasswordError('Неправильный адрес электронной почты или пароль');
            })
    };

    const auth = useAuth();
    const handleOIDCAuth = () => {
        auth.signinRedirect();
    };

    return (
        <>
            {loading ?
                <div className="main-page-container">
                    <Loader size={150}/>
                </div>
                :
                <div className="log-in">
                    <div className="log-in__logo">
                        {t('secretopus_name')}
                    </div>
                    <div className="log-in__content">

                        <div className="log-in__content-title">{t('login')}</div>
                        {/*<form onSubmit={handleSubmit}>*/}
                        <div className="log-in__content-login">
                            <div className="input-label">{t('input_email')}</div>
                            <input
                                onInput={(e) => loginHandler(e)}
                                onBlur={(e) => blurHandler(e)}
                                value={login}
                                name="login"
                                placeholder="user@example.com"
                                type="login"
                                className="log-in__content__input"
                            />
                            {loginDirty && loginError && (
                                <div className="log-in__error">{loginError}</div>
                            )}
                        </div>

                        <div className="log-in__content-password">
                            <div className="input-label">{t('input_password')}</div>
                            <input
                                onInput={(e) => passwordHandler(e)}
                                onBlur={(e) => blurHandler(e)}
                                value={password}
                                name="password"
                                placeholder={t('input_password')}
                                type="password"
                                className="log-in__content__input"
                            />
                            {passwordDirty && passwordError && (
                                <div className="log-in__error">{passwordError}</div>
                            )}
                        </div>

                        <button className={`log-in__content-button ${!!login && !!password ? 'active' : ''}`}
                                disabled={!password && !login}
                                onClick={handleSubmit}>
                            Log In
                        </button>

                        <div className={`log-in__content-text`}>
                            {/*Don’t have an account?*/}
                            {t('no_account')}?
                            <Link to={'/registration'} className="link">{t('create_now')}</Link>
                        </div>
                        {/*</form>*/}
                    </div>
                </div>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['token'])
};

const mapDispatchToProps = {LoginUser};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
