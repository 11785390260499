import { Mention } from "@tiptap/extension-mention";
import tippy from "tippy.js";
import { ReactRenderer } from "@tiptap/react";
import HelperEditorDropdown from "../../HelperEditorDropdown/HelperEditorDropdown";
import {deleteChar} from "../../../helpers";

export default {
    char: '@', // Указываем триггер
    allowSpaces: true,

    render: () => {
        let reactRenderer;
        let popup;

        return {
            onStart: (props) => {
                if (!props.clientRect) {
                    return;
                }
                // Создаем рендер компонента EditorDropdownActions
                reactRenderer = new ReactRenderer(HelperEditorDropdown, {
                    props,
                    editor: props.editor,
                });

                // Настраиваем tippy.js popup для отображения списка
                    popup = tippy("body", {
                        getReferenceClientRect: props.clientRect,
                        content: reactRenderer.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: "manual",
                        placement: "bottom-start",
                        onHide: () => {
                            if (popup) {
                                // Убедитесь, что ссылка на попап удалена
                                popup = null;
                            }
                            deleteChar(props.editor, "@");
                            // props.editor.commands.focus(); // Фокус на редактор
                        }
                    });
            },
            onUpdate(props) {
                reactRenderer.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup?.[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                console.log('popup',popup)
                popup?.[0].hide();

                return reactRenderer.ref?.onKeyDown(props);
            },

            onExit() {
                popup?.[0]?.destroy();
                reactRenderer?.destroy();
            },
        };
    },
};
//
// // Расширение Mention с использованием suggestion
// export default Mention.extend({
//     addOptions() {
//         return {
//             ...this.parent?.(),
//             suggestion: mentionSuggestion,
//         };
//     },
// });
