import React, {useEffect, useRef} from 'react';
import './NoteBlock.scss';
import {Text} from '../../../../modules';
import {Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../../../utils/helperFunctions";
import dayjs from "dayjs";
import {CheckActionItemIcon, PlayedIcon, PlayIcon, StarIcon} from "../../../../image";
import {ROUTERS_MAP} from "../../../../utils/routes";
import {Link} from "react-router-dom";
import {MarkDownText} from "../../../../shared/features/MarkDownText";

const NoteBlock = (props) => {
	const {note,toggleUserModal} = props;
	const {i18n, t} = useTranslation();
  const notes = note?.notes || [];
	const markdownRef = useRef();

	useEffect(() => {
		const container = markdownRef.current;
		if(!container) return;
		const handleClick = (event) => {
			const target = event.target;

			if(target.tagName === "SPAN" && target.hasAttribute("data-person-id")) {
				const personId = target.getAttribute("data-person-id");
				if(personId && personId !== 'null') {
					toggleUserModal(personId)
				}
			}
		};
		const currentContainer = container?.querySelectorAll('.markdown-text-person');

		if(currentContainer.length) {
			currentContainer.forEach(item => {
				item.addEventListener("click", handleClick);
			})
		}


		return () => {
			if(currentContainer.length) {
				currentContainer.forEach(item => {
					item.removeEventListener("click", handleClick);
				})
			}

		};
	}, [note, markdownRef]);

	return (
		<div className="note-block" ref={markdownRef}>
			<div className="note-block__header">
        <div className="note-block__header--wrap">
				<Text font="medium" size="f14">{t('favorites')}</Text>
				<Text font="book" size="f12" color="#828282">
					{formatDate(note?.video?.creation_time, i18n.language)}, {dayjs(note?.video?.creation_time).format('HH:mm:ss')}
				</Text>
        </div>
			<Tooltip
				placement="right"
				title={note.video?.description || ''}
			>
        <Link
	        to={{
		        pathname: `${ROUTERS_MAP.VIDEO}/${note?.video?.video_id}`,
		        state: {
			        routs: [
				        {
					        path: ROUTERS_MAP.NOTES,
					        name: t('notes')
				        },
				        {
					        path: ROUTERS_MAP.VIDEO,
					        name: note.video?.description || ''
				        }
			        ]
		        }
	        }}
          className="video-link">
          <div className="note-block__header--video">
            <PlayedIcon/>
            <Text font="medium" size="f12" color="#828282">{note.video?.description || ''}</Text>
          </div>
        </Link>
			</Tooltip>
      </div>
			<div className="note-block__body">
				<div className="note-block__body--content custom-scroll">
					{notes.map((note, index) => (
						<div className="note-block__body--content--item">
							<MarkDownText
								key={index}
								text={note.text}
							/>
							<div className="actions">
								{note?.is_starred ? <StarIcon className="star"/> : null}
								{note?.is_action_item ? <CheckActionItemIcon className="action-icon"/> : null}
							</div>
						</div>
					))}
				</div>
			</div>
</div>
)
	;
};

export default NoteBlock;