import {PROTOCOL_CONSTS} from "../constants";
import {_urlProtocol, request} from "../api";
import {UTIL_CONSTS} from "../constants/utilConsts";

export const GetProtocol = (id, uuid,setData) => {
    let requestData = {
        url: `${_urlProtocol}/${id}`,
        method: "GET",
    };

    uuid && (requestData.url = requestData.url + `?uuid=${uuid}`)

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                if (setData) {
                    return data
                } else {
                    await dispatch({
                        type: PROTOCOL_CONSTS.GET_PROTOCOL,
                        payload: data
                    })
                }
            })
    };
};

export const AddProtocol = (data, protocolIndex) => {
    let requestData = {
        url: `${_urlProtocol}/add_text_block`,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: PROTOCOL_CONSTS.ADD_PROTOCOL,
                    payload: {
                        data:  {
                            ...data,
                            id: res?.data?.protocol_text_id,
                            marked: false
                        },
                        protocolIndex
                    }
                })
            })
    };
};

export const UpdateProtocol = (id, data,setData) => {
    const requestData = {
        url: `${_urlProtocol}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                if (setData) {
                    return data
                } else {
                    dispatch({
                        type: PROTOCOL_CONSTS.UPDATE_PROTOCOL,
                        payload: {
                            id, data
                        }
                    });
                }
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};
export const UpdateProtocolPosition = (id, data) => {
    const requestData = {
        url: `${_urlProtocol}/${id}/order`,
        token: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROTOCOL_CONSTS.UPDATE_PROTOCOL_POSITION,
                    payload: {
                        id, data
                    }
                });
            })
            .catch((err) => {
                console.log(err, 'ERROR');
            })

    }
};

export const DeleteProtocol = data => {
    const requestData = {
        url: `${_urlProtocol}/${data?.id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROTOCOL_CONSTS.DELETE_PROTOCOL,
                    payload: data
                });
            })
            .catch(() => {
            })
    }
};

export const DeleteProtocolHighlight = data => {
    const requestData = {
        url: `${_urlProtocol}/highlights`,
        token: true,
        method: "DELETE",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PROTOCOL_CONSTS.DELETE_PROTOCOL_HIGHLIGHTS,
                    payload: data?.protocol_text_id
                });
            })
            .catch(() => {
            })
    }
};

export const AddProtocolHighlight = (data) => {
    let requestData = {
        url: `${_urlProtocol}/highlights`,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: PROTOCOL_CONSTS.ADD_PROTOCOL_HIGHLIGHTS,
                    payload: data?.protocol_text_id
                })
            })
    };
};


export const ToggleEditProtocolFlagService = (data) => {
    console.log('data',data)
    return dispatch => {
        dispatch({
            type: PROTOCOL_CONSTS.TOGGLE_EDIT_FLAG,
            payload: data
        })
    }
};