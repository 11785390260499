import {Mention} from "@tiptap/extension-mention";
import tippy from "tippy.js";
import {ReactRenderer} from "@tiptap/react";
import EditorDropdownActions from "../../ActionsEtitorDropdown/EditorDropdownActions";

const actionsMention = {
    char: '/', // Указываем триггер
    allowSpaces: true,
    render: () => {
        let reactRenderer;
        let popup;

        return {
            onStart: (props) => {
                if (!props.clientRect) {
                    return;
                }
                reactRenderer = new ReactRenderer(EditorDropdownActions, {
                    props,
                    editor: props.editor,
                });
                popup = tippy("body", {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                    onHide: () => {
                        if (popup) {
                            // Убедитесь, что ссылка на попап удалена
                            popup = null;
                        }
                        // props.editor.commands.focus(); // Фокус на редактор

                        // deleteChar(props.editor, )
                    }
                });
            },

            onUpdate(props) {
                reactRenderer.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup?.[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key === "Escape") {
                    popup[0].hide();
                    return true;
                }

                return reactRenderer.ref?.onKeyDown(props);
            },

            onExit() {
                popup?.[0].destroy();
                reactRenderer.destroy();
            },
        };
    },
};

// Расширение Mention с использованием suggestion
export default Mention.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            suggestion: actionsMention,
        };
    },
});
