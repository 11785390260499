import { Node } from "@tiptap/core";

export const NonEditableText = Node.create({
    name: "nonEditableText",

    group: "inline",

    inline: true,

    atom: true, // Делает нод "атомарным", чтобы его нельзя было редактировать

    addAttributes() {

        return {
            text: {
                default: "",
            },
            personId: {
                default: null,
                parseHTML: (element) => element.getAttribute('personId'),
                renderHTML: (attributes) => {
                    return attributes.personId ? {personId: attributes.personId } : {};
                },
            },
            personType: {
                default: null,
                parseHTML: (element) => element.getAttribute('personType'),
                renderHTML: (attributes) => {
                    return attributes.personType ? {personType: attributes.personType } : {};
                },
            },
            personName: {
                default: null,
                parseHTML: (element) => element.getAttribute('personName'),
                renderHTML: (attributes) => {
                    return attributes.personName ? {personName: attributes.personName } : {};
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "span[data-type='non-editable']",
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        // Убираем contenteditable="false", чтобы можно было выделить и скопировать текст
        console.log('HTMLAttributes',HTMLAttributes)
        return [
            "span",
            {
                ...HTMLAttributes,
                "data-type": "non-editable",
                style: "user-select: text; pointer-events: all;",
                class: 'markdown-user'
            },
            HTMLAttributes.text,
        ];
    },

    addCommands() {
        return {
            insertNonEditableText:
                (attrs) =>
                    ({ chain }) => {
                        console.log('attrs',attrs)
                        return chain()
                            .insertContent({
                                type: "nonEditableText",
                                attrs: {...attrs},
                            })
                            .run();
                    },
        };
    },
});
